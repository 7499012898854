@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
@import "../../../../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/components/Atoms/PageTitle/PageTitle.scss";

.page-title__wrapper {
  background-color: $shale-blue;

  .page-title {
    margin: 0;
    padding: $spacer*0.5 0;
    @include font-size(35px);
    color: $white;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
