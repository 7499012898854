@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__slideshow {

  .heading {
    font-weight: 600;
    line-height: 1.4;
  }

}
