@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.heading {
  color: $blue;
  font-family: var(--sp-font-secondary);

  &--label {
    font-family: var(--sp-font-secondary);
    font-size: 0.9rem;
    font-weight: 700;
    color: $dark-gray;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: $spacer*0.5;
  }

  &--hero {
    font-family: var(--sp-font-secondary);
    font-size: 2.5rem;
    font-weight: 300;
    color: $white;
    text-shadow: 0 2px 4px #000000;
    letter-spacing: 1px;
    margin-bottom: $spacer*0.5;
  }

  &--underline {
    @include font-size(26px);
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: solid 1px $light-gray;
    position: relative;

    &:before {
      background: $yellow;
      height: 4px;
      margin: -4px 0 0;
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      width: 200px;
    }
  }
}

h3 {
  color: $blue;

  &.heading--teaser {
    text-transform: none;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: $spacer*0.5;
		line-height: 1.3;

    a {
      color: $shale-blue;
    }
  }
}
