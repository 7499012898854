@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


header {
  color: $white;
  background-color: $blue;

	@include media-breakpoint-down(md) {
		overflow-x: hidden;
	}

  .container {
    @include media-breakpoint-down(md) {
  		max-width: 100vw;
      padding: 0;
  	}
  }

  .search-form {
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    align-self: center;
    height: 48px;
    order: 1;
    width: 100%;
    position: relative;
    
    @include media-breakpoint-up(lg) {
      width: 5%;
      padding-top: 5px;
      padding-bottom: 1px;
      order: 2;
    }

    svg {
      width: 25px;
      height: 25px;
    }

    .search-collapse-form {
      position: relative;
      width: 100%;
      padding: 0 0.8em 0 0.9em;
      background-color: $shale-blue;
      color: $white;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4);
      align-items: center;
      justify-content: space-between;

      &.show {
        display: flex;
      }

      @include media-breakpoint-down(md) {
        &.fade:not(.show), &.fade {
          opacity: 1;
        }
      }

      @include media-breakpoint-up(lg) {
  			position: absolute;
        top: 38px;
        right: 0;
        width: 310px;
        padding: 8px 3px 8px 10px;
        background-color: $white;
        color: $body-text;
  		}
    }

    form {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    input[type="search"] {
      background-color: inherit;
      color: $white;
      border: none;
      font-style: italic;
      width: 90%;
      
      &::placeholder { 
        color: inherit;
        opacity: 1;
      }

      @include media-breakpoint-up(lg) {
        width: 270px;
        color: $body-text;
      }

      &:focus,
      &:focus-visible {
        box-shadow: none;
        // border: none;
        outline-color: transparent;
      }

      &::-webkit-search-cancel-button {
        display: none;
      }
    }

    .search-collapse-button {
      display: none;
      border: none;
      border-radius: 0;
      background-color: $shale-blue;
      padding: 0px;
      width: 32px;
      height: 32px;

      @include media-breakpoint-up(lg) {
        display: block;
      }

      &:focus {
        outline: solid;
      }
    }

    button[type="submit"] {
      padding: 0;
      height: 100%;

      svg {
        fill: $white;
      }

      @include media-breakpoint-up(lg) {
        color: $blue;

        svg {
          fill: $shale-blue;
        }
      }
    }
  }

  .navbar-brand {
    color: $yellow;
    text-decoration: none;
  }
}

.logo {

	&-area-wrapper {
		padding: $spacer*0.7 0 $spacer*0.7 $spacer;
		width: 100%;

		@include media-breakpoint-up(lg) {
			max-width: 100%;
      padding-left: 0;
		}

		img {
			width: 100%;
		}
	}

	&-wrapper {
		display: grid;
		grid-template-columns: 100px auto;
		grid-template-rows: 1fr;
		grid-column-gap: 25px;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
    justify-content: start;
	}

  &-mobile {
    display: block;
    width: 100%;
    max-width: 500px;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &-desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      width: 40%;
      display: flex;
      order: 1;
    }
  }
}

.header__logo {
  grid-row-start: 1;
  grid-row-end: span 2;
  align-self: center;
  justify-self: end;
}

.header__sitename {
  color: $yellow;
  font-family: var(--sp-font-primary);
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;

  &--prefix {
    font-size: 13px;
    display: block;
    align-self: end;
    line-height: 16px;
  }

  &--name {
    grid-column-start: 2;
    align-self: start;
  }
}

.utility_menu_wrapper {
  order: 3;

  @include media-breakpoint-up(lg) {
    order: 2;
    padding: 0;
  }
}

.header_menu_wrapper {
  order: 2;
  width: 100%;
  display: flex;
  flex-direction: column;

  

  @include media-breakpoint-up(lg) {
    order: 4;
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-start;
    border-top: solid 1px $shale-blue;
  }
}

.header_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 200;

  .navbar-collapse {
    @include media-breakpoint-up(lg) {
      order: 1;
      padding: 0;
    }
  }

  .navbar {
    width: 100%;
    padding: 0;
    flex-flow: wrap;
  }

  .navbar-nav {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    order: 2;

    @include media-breakpoint-up(lg) {
      align-items: center;
      order: 1;
    }
  }
}

.break {
  flex-basis: 100%;
  height: 0;
  order: 3;
}

// Dark skin
header {
  &.dark {
    background-color: $blue;

    .header__logo {
      filter: invert(100%) sepia(3%) saturate(11%) hue-rotate(16deg) brightness(103%) contrast(104%);
    }  
  }

  // Light skin
  &.light {
    background-color: $white;

    .header__sitename {
      color: $shale-blue;
    }

    .header__logo {
      filter: invert(10%) sepia(36%) saturate(6227%) hue-rotate(217deg) brightness(102%) contrast(107%);
    }
  }
}  