@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


@mixin button-style($button-color) {
  border: 1px solid $button-color;
  background: $button-color;

  &:hover {
    background-color: darken($button-color, 10%);
    text-decoration: none;
  }
}

.button,
a.button {
  border-radius: 0;
  color: $white;
  background-color: $shale-blue;
  border: 1px solid transparent;
  margin: $spacer 0;
  padding: $spacer*0.3 $spacer*0.5;
  display: inline-flex;
  align-items: center;

  @include media-breakpoint-up(sm) {
    padding: $spacer*0.5 $spacer*1.3;
  }

  &:hover {
    background-color: $white;
    transition: background-color 0.2s ease-out;
    border: 1px solid $shale-blue;
    color: $shale-blue;
    text-decoration: none;

    .button__icon {
      transform: translateX(7px) rotate(180deg);
      transition: all 0.2s ease-out;
      color: $shale-blue;
    }
  }

  &__text, span {
    font-family: var(--sp-font-primary);
    font-size: 0.9rem;
    font-weight: 600;
    color: inherit;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    @include media-breakpoint-up(sm) {
      font-size: 0.9rem;
    }
  }

  &--cta {
    @include button-style($orange);
    color: $white;
  }

  &--footer {
    background-color: $yellow;
    color: $blue;
    padding: $spacer*0.5 $spacer*2;

    @include media-breakpoint-up(lg) {
      padding-right: $spacer*2.5;
      padding-left: $spacer*2.5;
    }

    &:hover {
      background-color: var(--sp-color-accent-two);
      color: $blue;
      border-color: transparent;
    }

    .button__icon {
      display: none;
    }

    .button__text {
      font-weight: bold;
    }
  }

  &--plain {
    padding: 0;
    border: none;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      text-decoration: none;
      box-shadow: none;
      border: none;
    }
  }

  &--white {
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &--secondary {
    color: $body-text;
    border: solid 1px $body-text;
    background-color: transparent;

    .button__icon {
      color: inherit !important;
    }    

    &:hover {
      color: $white;
      background-color: $body-text;
      border: solid 1px $body-text;

      .button__icon {
        color: $white !important;
      }
    }


  }  

  &--blue {
    color: $shale-blue;
    &:hover {
      color: $blue;
      background-color: transparent;
    }

    .button__icon {
      color: $shale-blue !important;
    }
  }

  &__text + &__icon,
  &__icon + &__text {
    margin-left: 0.5rem;
  }

  &__icon {
    position: relative;
    font-size: 26px;
    transform: rotate(180deg);
    transition: all 0.2s ease-out;
    color: $yellow;
  }
}

// Misc overrides
.main-content {
  a.button::after {
    display: none;
  }
}
