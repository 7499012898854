@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";

@import "../../Atoms/Button/Button.scss";

footer {
  background-color: $dark-gray;
  margin-top: $spacer * 4;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $white;
  font-family: var(--sp-font-primary);
  font-size: 0.9rem;

  h4 {
    margin-top: 0;
  }

  .footer {
    &-top {
      padding-top: $spacer * 2;
      padding-bottom: $spacer * 2;
    }

    &-bottom {
      padding-top: $spacer * 2;
      padding-bottom: $spacer * 2;
      background-color: $blue;
    }
  }

  a {
    color: $white;
    &:hover {
      text-decoration: underline;
      color: $white;
    }
  }

  .site_name {
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1.2rem;
    text-transform: capitalize;

    a {
      color: $white;
      text-decoration: none;
    }
  }

  .footer__email,
  .footer__phone,
  .footer-contact p {
    font-size: 1.2rem;
    font-weight: 600;

    svg {
      color: $yellow;
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }
  }

  .footer__cta-primary {
    padding: 0.8em 1.5em;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.28px;
    margin-bottom: $spacer;
    display: inline-block;

    &:hover {
      background-color: darken($orange, 10%);
      text-decoration: none;
    }
  }

  .footer__cta-secondary {
    background-color: $shale-blue;
    border: none;
    color: $white;
    padding: 0.8em 1.5em;
    margin-top: $spacer * 0.5;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.28px;
    display: inline-block;

    &:hover {
      background-color: darken($shale-blue, 10%);
      text-decoration: none;
    }
  }

  .button--footer,
  a.button--footer {
    @extend .button--footer;
  }

  .heading {
    color: $white;
    font-size: 1rem;
    margin-bottom: 0;
    font-weight: 700;
  }

  .footer__social-icons-wrapper {
    margin-top: $spacer;

    svg {
      height: 40px;
      width: 40px;

      &:hover,
      &:focus {
        fill: $yellow;
      }
    }

    a {
      display: inline-block;
      margin: 0.5em 1em 0.5em 0;

      // @include media-breakpoint-up(md) {
      //   height: 100px;
      //   width: 100px;
      //   margin: 0.5em 1.5em 0.5em 0;
      // }
    }
  }

  .separator {
    height: 1px;
    background-color: $shale-blue;
    border: none;
    margin: 2em 0;

    @media (max-width: 768px) {
      height: 0px;
    }
  }

  .footer__copyright-links-wrapper {
    a {
      color: $white;
      font-size: 14px;
      text-decoration: none;
      margin-right: 2em;

      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .col-md-4 {
    @include media-breakpoint-down(sm) {
      &:nth-child(1) {
        border-bottom: 1px solid $shale-blue;
        padding-bottom: $spacer;
      }
      &:nth-child(3) {
        border-top: 1px solid $shale-blue;
        padding-top: $spacer;
      }
    }
  }

  .copyrightWrapper {
    border-top: 1px solid $shale-blue;
    display: flex;
    align-items: center;
    padding-top: $spacer * 2 !important;

    @include media-breakpoint-up(md) {
      border-top: none;
      padding-top: 0;
    }
  }

  .footer__copyright-Drupallinks-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
