@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.imagecard {
  transition: transform .2s;
  margin: $spacer*2 0;

  &:hover {
    box-shadow: 0 1px 32px 0 rgba(0, 0, 0, 0.8);

    @include media-breakpoint-up(md) {
      transform: scale(1.05);
    }
  }

  &__content {
    padding: $spacer*1.5;
    background-color: $yellow;
    height: 100%;

    @include media-breakpoint-up(md) {
      box-shadow: none;
    }

    p {
      margin: 0 0 24px;
    }

    .button {
      margin: 0;
      &:hover {
        color: $white;
      }
    }
  }

  &__heading {
    font-family: var(--sp-font-secondary);
    font-weight: 400;
    font-size: 1.8rem;
    color: $blue;
    text-transform: initial;
    color: $blue;
    margin: 0 0 24px;
  }

  &__link {
    color: $white;
  }

  &__image {
    overflow: hidden;
    background-color: $white;

    .image {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100% !important;
      max-height: 100%;

      img {
        position: relative;
      }
    }
  }

  // modifiers
  &--even {

    & .imagecard__content {
      background-color: var(--sp-color-accent-two);
    }

    a:hover {
      color: $blue;
    }
  }
}
