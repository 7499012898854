@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.sidebar-menu {
  font-family: var(--sp-font-primary);
  padding: $spacer*0.33 $spacer;
  border-bottom: 4px solid $shale-blue;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  background-color: var(--sp-color-accent-three); 
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacer*3;
    padding: $spacer*1.5;
    box-shadow: none;
  }

  .navbar-nav {
    width: 100%;
  }

  .navbar-collapse,
  .navbar-toggler {
    margin: 0 auto;
    padding: 0 $spacer*0.5;
    @each $breakpoint, $container-max-width in $container-max-widths {
      @include media-breakpoint-up($breakpoint) {
        max-width: $container-max-width;
        padding: 0 $spacer;
      }  
    } 
  }

  .navbar-collapse {
    @include media-breakpoint-up(lg) {
      max-width: 100%;
      margin: 0;
      padding: 0;
    } 
  }

  .dropdown-toggle {
    white-space: normal;

    &:after {
      content: none;
    }
  }  

  .navbar-toggler {
    width: 100%;
    margin: 0 auto;
    padding-top: $spacer*0.5;
    padding-bottom: $spacer*0.5;
    text-align: left;
    color: $blue;
    border: none;
    background: url("../../../../../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/images/minus_icon_blue.svg") right center
      no-repeat;
    background-size: 24px 24px;

    &.collapsed {
      background: url("../../../../../../../../node_modules/@dws-contributes/sitespro-gatsby-base/src/images/plus_icon_blue.svg") right center
        no-repeat;
			background-size: 24px 24px;	
    }

    img.navbar-toggle-icon {
      float: right;
    }
  }

  .navbar-toggle-word {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.71;
    color: $shale-blue;
    font-family: var(--sp-font-primary);
    letter-spacing: 1px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    width: 100%;

    &:first-child > .nav-item {
      border-bottom: 1px solid $light-gray;
    }

    &.dropdown {
      padding-left: $spacer*0.8;
			padding-top: $spacer;

			@include media-breakpoint-up(lg) {
				padding-top: 0;
			}
			
    }

    .nav-item {
      padding: 0.2em 0;
      width: 100%;

      &:last-of-type {
        border: none;
      }

      .nav-link { 
        font-size: 0.9rem;
        color: $dark-gray;
        text-decoration: none;
        padding: 5px 0;

        &.active {
          color: #036CA0 !important;
          font-weight: bold;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
