@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__pullquote {
  padding: $spacer * 3 $spacer * 3 0;
  border: solid 8px var(--sp-color-accent-three);
  width: 100%;
  margin: $spacer 0;
  text-align: center;	

  @include media-breakpoint-down(md) {
    padding: $spacer * 2 $spacer 0;
  }

  p {
    color: $blue;
    @include font-size(25px);
    line-height: 140%;
    font-style: italic;
    font-weight: 300;
    margin-bottom: $spacer;

    @include media-breakpoint-down(md) {
      font-size: 1.1rem;
    }

    &:first-of-type::before {
      content: "\201C";
      display: block;
      font-family: 'Times New Roman', Times, serif;
      color: $orange;
      font-weight: 600;
      font-size: 100px;
      font-style: italic;
      margin: 5px auto;
      color: var(--sp-color-accent-two);
    }

    &.pullquote-attribution {
      font-family: var(--sp-font-secondary);
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: $spacer * 1.5;

      @include media-breakpoint-down(md) {
        font-size: 0.9rem;
      }
    }
  }
}
