@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.navbar-nav .actionmenu li a.nav-link {
  background: $yellow;
  color: $blue;
  padding: $spacer $spacer*2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.2s ease-out;

  &:hover,
  &:focus,
  &.active {
    background-color: var(--sp-color-accent-two);
    color: $blue;
    transition: background-color 0.2s ease-out;
    text-decoration: none;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 $spacer*2.5;
  }
}

.actionmenu {
  margin-top: $spacer;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;

    li {
      font-family: var(--sp-font-primary);
      font-size: 0.9rem;
      font-weight: bold;
      display: flex;

      a {
        text-transform: uppercase;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
      }
    }
  }
}
