@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.layout-no-sidebars {
  overflow-x: hidden;
}

.layout-main-wrapper {
  display: grid;
  align-items: start;
  grid-template-columns: 1fr;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 23.5% 1fr;

    &.layout-no-sidebar {
      grid-template-columns: 0 1fr;

      .main-content {
        padding-left: 0;
      }
    }
  }
}

.main-content {
  padding-bottom: $spacer*2;
	outline-style: none;

	&:focus {
		outline-style: none;
	}
  
  @include media-breakpoint-up(lg) {
    grid-column: 2;
    padding-left: $spacer*3;
    padding-top: $spacer;
  }

  @include media-breakpoint-down(lg) {
    .breadcrumb__wrapper {
      display: none;
    }
  }    
}  

.main-sidebar {
  width: 99.9vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;

  @include media-breakpoint-up(lg) {
    width: 100%;
    left: 0;
    height: 100%;
    margin-left: 0;
    margin-top: $spacer*2;

    .container {
      padding: 0;
    }
  }

  .breadcrumb__wrapper {
    display: block;
    width: auto;
    margin-right: var(--bs-gutter-x, 1.2rem);
    margin-left: var(--bs-gutter-x, 1.2rem);

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.sidebar {
  &-content {
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.25);
    border-right: solid 4px $shale-blue;
    margin: $spacer*2 auto;
    padding: $spacer $spacer 0 $spacer;
    font-family: var(--sp-font-secondary);
    font-size: 0.95rem;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin: 0;
      position: sticky;
      top: 20px;
    }

    @include media-breakpoint-down(lg) {
      width: auto;
      margin-right: var(--bs-gutter-x, 1.2rem);
      margin-left: var(--bs-gutter-x, 1.2rem);
    }

    h3 {
      color: $orange;
      font-size: 1rem;
      line-height: 1.5;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .paragraph__wysiwyg {
     padding-top: $spacer;
    }
  }

  &__heading {
    margin-top: 0;
    font-size: 1.3rem;
    color: $dark-gray;
  }
}  

// skip link
:root {
  --reach-skip-nav: 1;
}

[data-reach-skip-nav-link] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}

[data-reach-skip-nav-link]:focus {
  padding: $spacer*0.5 $spacer;
  position: relative;
  margin: 0.2%;
  text-align: center;
  width: 99.6%;
  background: white;
  z-index: 1;
  height: auto;
  clip: auto;
  display: block;
}
