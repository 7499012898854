@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.profile {
  margin-top: $spacer;
  margin-bottom: $spacer*3;

  svg {
    color: $shale-blue;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 5px;

    & + p {
      display: inline-block;
    }
  }

  &__head {
    &--wrapper {
      display: flex;
      flex-direction: row;
      @include media-breakpoint-down(sm) {
				flex-direction: column;
			}
    }

    &--image {
      margin: 0 auto $spacer auto;

      @include media-breakpoint-up(md) {
				max-width: 288px;
        margin-right: $spacer*3;
			}
    }

    &--content {
      width: 100%;
      margin-top: 0;

      @include media-breakpoint-down(lg) {
        margin-top: $spacer;
			}
    }

    &--heading {
      border-bottom: 1px solid $yellow;
      padding-bottom: $spacer*0.2;
      margin-bottom: $spacer;
      width: 100%;

      .heading {
        font-size: 1.6rem;
        margin-top: 0;
        font-weight: 600;
        margin-bottom: $spacer*0.2;
      }
    }
  }

  &__contact {
    font-family: var(--sp-font-primary);
    font-size: 1.1rem;
    line-height: 1.4;
    // letter-spacing: 1px;
    margin-bottom: $spacer*1.5;

    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    &--heading .eyebrow {
      margin-bottom: 0;    
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.1rem;
      color: $blue;
    }

    &--address {
      .profile__address {
        margin-bottom: 0;
        &--2, &--3 {
          margin-left: 29px;
        }
      }
    }

    &--email {
      word-wrap: break-word;
      text-transform: initial;
      letter-spacing: normal;
      font-weight: normal;
    }

    &--website_url {
      max-width: 100%;
      word-wrap: break-word;
      text-transform: initial;

      a {
        letter-spacing: 0;
        font-weight: normal;
      }
    }
  }

  &__image {
    margin-bottom: $spacer;
  }

  .related-content {
    margin-bottom: 2.5rem;
  }

  &__related-content-item {
    font-family: var(--sp-font-secondary);
    border-bottom: solid 1px $light-green;
    padding: $spacer 0;
    &:last-of-type {
      border-bottom: none;
    }
    &:first-of-type {
      padding-top: 0;
    }

    p {
      margin-bottom: 0;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    time {
      color: $teal;
    }
  }
}

.profile-teaser {
  margin-bottom: $spacer*2;
  font-family: var(--sp-font-primary);
  word-wrap: break-word;
  font-family: var(--sp-font-primary);

  svg {
    color: $shale-blue;
    margin-right: 0.3em;
    width: 24px;
    height: 24px;
  }

  img {
    margin-bottom: $spacer*0.5;
  }

  &__email, &__phone {
    font-size: 1rem;
    font-weight: 600;
  }

  &__head {
    margin-bottom: $spacer;

    .heading {
      margin: 0;
    }
  }

  h4.heading {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__role {
    font-size: 1.3rem;
    font-weight: normal;
    line-height: 1.3;
    color: $body-text;
    margin-bottom: $spacer;
  }

  &__bio {
    margin-top: $spacer*0.5;
  }

  &__name {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1rem;
    font-family: var(--sp-font-primary);

    a {
      color: $shale-blue;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
}
