@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.header_wrapper {
  .mainmenu .navbar-nav {
    align-items: flex-start;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
      justify-content: flex-start;
    }
  }
}

.mainmenu,
.navbar-dark .mainmenu.navbar-nav {
  .nav-item {
    padding: 2px 5px 2px 0px;

    @include media-breakpoint-down(xxl) {
      margin-right: 0;
    }
  }
  
  // link label
  .nav-link {
    font-family: var(--sp-font-primary);
    font-size: 0.95rem;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    display: inline-block;
    padding: 0;
    line-height: 1.4;
    transition: all 0.2s ease;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    padding: 2px 0;

    @include media-breakpoint-down(xxl) {
      font-size: 0.9rem;
      letter-spacing: 0;
    }
  }

  // link toggle/caret
  .dropdown-toggle {
    border: none;
    padding: 0 8px;
    margin-right: $spacer;
    margin-top: 4px;
    color: #1599C6;
    background-color: transparent;
    transition: all 0.2s ease;

    &[aria-expanded="true"] {
      color: $yellow;
    }
  }

  // Top level menu items hover state
  .nav-item:hover {
    .nav-link {
      color: $yellow;
      border-bottom: solid 1px $yellow;
      // text-decoration: underline $yellow;
      transition: all 0.2s ease;
    }
  }

  // dropdown menu
  .dropdown-menu {
    border: none;
    border-radius: 0px;
    background-color: $blue;
    min-width: 300px;
    padding: 9px 0 0 0;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4);
    transition: all 0.2s ease;
    top: 96%;
  }

  .dropdown-link {
    color: $white;
    font-size: 0.9rem;
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover,
    &:focus {
      background-color: $yellow;
      color: $blue;
      transition: all 0.2s ease;
    }
  }

  // top level link label
  .nav-item {
    padding: 0;
  }

  // mobile styles
  @include media-breakpoint-down(md) {
    
    // Remove the underlining
    .nav-item:hover {
      .nav-link {
        border-bottom: none;
      }
    }

    .nav-link {
      font-size: 1.15rem;
      line-height: 1.9;
      color: $yellow;
      text-transform: initial;
      padding: $spacer*0.2 $spacer;
      -webkit-appearance: none;
    }

    .dropdown-menu {
      box-shadow: none;
    }

    .dropdown-link {
      padding: $spacer*0.2 1.2rem;
    }

    // toggle - change to +/-, align to right
    .dropdown-toggle {
      cursor: pointer;
      height: 16px;
      position: absolute;
      top: 20px;
      right: 5px;
      width: 16px;
      border: none;

      &:before,
      &:after {
        background: $yellow;
        content: '';
        height: 2px;
        right: 5px;
        position: absolute;
        top: 0;
        width: 16px;
        border: none;
        transition: transform 0.2s ease;
      }

      &:after {
        transform-origin: center;
        background: $yellow;
      }

      &[aria-expanded="false"] {
        &:after {
          transform: rotate(90deg);
        }
        &:before {
          transform: rotate(180deg);
          
        }
      }
    }

  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

// Hamburger menu label (MENU)
.navbar-hamburger-button_label {
  display: inline-block;
  font-size: 0.95rem;
  color: $white;
  font-family: var(--sp-font-primary);
  text-transform: uppercase;
  font-weight: 600;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  letter-spacing: 1px;
}

.navbar-toggler {
  display: block;
  border: none;
  border-radius: 0;
  background-color: transparent;
  margin: 0;
  padding: $spacer * 0.5;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: transform 0.2s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: $white;
  width: 100%;
  border-top: solid 1px $shale-blue;

  &:focus {
    outline: 2px solid $white;
  }

  &:active {
    transform: scale(0.99);
  }

  .hamburger-box {
    width: 20px;
    height: 11px;
    display: inline-block;
    position: relative;
    margin-bottom: 1px;
  }

  .hamburger-inner {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    top: 50%;

    &,
    &::before,
    &::after {
      width: 100%;
      height: 2px;
      content: "";
      display: block;
      background-color: $white;
      border-radius: 1px;
      position: absolute;
      transition: transform 0.2s ease;
    }

    &::before {
      top: -5px;
      transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out,
        background-color 0.15s ease;
    }

    &::after {
      bottom: -5px;
      transition: bottom 0.1s 0.34s ease-in,
        transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19),
        background-color 0.15s ease;
    }
  }

  &[aria-expanded="false"] {
    .label-close {
      display: none;
    }
  }

  &[aria-expanded="true"] {
    .label-menu {
      display: none;
    }

    .hamburger-inner {
      transform: rotate(225deg);
      transition-delay: 0.2s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        background-color: $shale-blue;
        opacity: 0;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.2s ease-out,
          transform 0.3s 0.24s cubic-bezier(0.215, 0.61, 0.355, 1),
          background-color 0.15s ease;
      }
    }
  }
}

.navbar-dark .navbar-toggler {
  border-color: $shale-blue;
}

/* Main menu links */
.mainmenu .nav-item:hover .nav-link, 
.navbar-dark .mainmenu.navbar-nav .nav-item .nav-link:focus-visible {
    border-bottom: 1px solid #ffd960;
    color: #ffd960;
}
/* Main menu triangle buttons */
.mainmenu .dropdown-toggle:focus-visible, 
.navbar-dark .mainmenu.navbar-nav .dropdown-toggle:focus-visible {
    color: #ffd960;
}
/* contact us */
.navbar-nav .actionmenu li a.nav-link:focus-visible {
    outline: 2px solid #fff;
    box-shadow: 0 0 0 5px #000;
}