@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__hero-carousel {
	margin-bottom: 0;

	@include media-breakpoint-up(lg) {
		padding-bottom: 0;
	}

  @include media-breakpoint-down(sm) {
    .container {
      position: relative;
    }
  }

  .control-prev, 
  .control-next {
    border: none;
    background-color: transparent;
		height: 40px;

    &-icon svg {
      text-align: center;
      margin: auto;
      width: 46px;
      height: 46px;
      fill: $white;

      @include media-breakpoint-down(sm) {
        width: 30px;
        height: 30px;
      }
    }
  }

  .control-prev-icon svg {
    transform: rotate(180deg);
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 66.67% 33.33%;
    grid-template-rows: auto auto;
    position: relative;
  }

  &__bottom-placeholder {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    bottom: 0;
    padding-top: 200px;
    margin-top: 100px;
    background-color: var(--sp-color-accent-three);
    z-index: -1;

    @include media-breakpoint-down(sm) {
      position: absolute;
      margin-top: 0;
      margin-bottom: 0;
      top: 230px;
      height: 210px;
    }
  }

  &__image {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    transition: 0.25s opacity;

    @include media-breakpoint-down(sm) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      max-height: 230px;
    }

    .image {
      position: relative;
      height: 0 !important;
      width: 100% !important;
      padding-top: 66.67%;
      object-fit: cover;
      display: block !important;

      img {
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      @include media-breakpoint-down(md) {
        width: 100% !important;
      }
    }
  }

  &__item-wrapper {
    @include media-breakpoint-down(md) {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }
  }

  &__info {
    width: 100%;
    background-color: $blue;
    padding: $spacer;
    position: absolute;
    min-height: 200px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;

    @include media-breakpoint-down(sm) {
      min-height: 200px;
      padding: $spacer $spacer*0.5 $spacer*0.5 $spacer*0.5;
      position: relative;
    }
  }  

  &__content {
    flex-grow: 1;
    color: $white;
    display: flex;
    flex-direction: column;
    padding: 0 $spacer;

    @include media-breakpoint-down(sm) {
      padding: 0 $spacer*0.5;
    }

    .eyebrow {
      text-align: left;
      color: $white;
      font-size: 1.1rem;
      margin-bottom: $spacer*0.33;
    }

    h4 {
      text-align: left;
      text-transform: unset;
      color: $white;
      font-weight: 300;
      line-height: 1.3;
      @include font-size(40px);
      margin: 0;
    }

    a.button {
      text-align: left;
      color: $white;
      background-color: transparent;
      border-color: $white;
      box-shadow: none;
      width: fit-content;

      svg, .button__icon {
        color: $white !important;
      }

      &:hover {
        text-decoration: underline;
        background-color: $shale-blue;
      }
    }
  }

  &__title-list {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    padding-top: $spacer;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      max-height: 306.69px;
    }

    .nav-item {
      border-top: 1px solid $yellow;
      margin: 0;
      padding: 0;
      margin-left: $spacer*2;
      
      &:first-of-type {
        border-top: none;
      }

      &:hover {
        cursor: pointer;
      }
    }
    
    .nav-link {
      font-size: 1.1rem;
      line-height: 1.7;
      text-decoration: none;
      margin-bottom: 0;
      padding: $spacer;
      
      &.active {
        font-weight: 700;
      }    
      
      @include media-breakpoint-down(lg) {
        padding: $spacer*0.66;
        font-size: 0.95rem;
      }

    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}