@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.iconcard {
  max-width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(md) {
    max-width: 288px;
  }

  &__content {
    padding: $spacer*1.5;
  }

  &__heading {
    font-weight: normal;
  }

  img {
    display: block;
    max-width: 164px;
    width: 100%;
    height: auto;
  }

}

.iconcard, .button span {
  line-height: 24px;
}
