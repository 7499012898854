@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";

@import "../../Atoms/Button/Button.scss";

.paragraph__hero-video-text {
  .html5-video-player {
    background-color: transparent !important;
  }
  
  .row {
    margin: $spacer*2 0;
    flex-direction: column-reverse;

    @include media-breakpoint-down(md) {
      margin: 0;
    }
  }

  div[class^="col-"] {
    width: 100%;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  .hero-text {
    background: $blue;
    color: #fff;
    height: 100%;
    margin-left: $spacer * 4;
    padding: $spacer;

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }

    .eyebrow {
      color: $yellow;
      margin: 0;
    }

    h2 {
      font-weight: bold;

      @include media-breakpoint-down(md) {
        font-size: calc(1.375rem + 0.5vw);
      }
    }

    h3 {
      font-weight: 300;
      text-transform: initial;

      @include media-breakpoint-down(md) {
        font-size: calc(1.2875rem);
      }
    }

    h2,
    h3 {
      margin-top: 0;
      margin-bottom: 8px;
      color: $white;
    }

    a {
      color: $white;

      &.button {
        @extend .button--secondary;
        width: fit-content;
        border-color: $white;
        color: $white;

        &:hover {
          background-color: $shale-blue;
        }
      }
    }
  }
}
