@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__content-reference {
  h4, h5 {
    font-size: 26px;
    font-weight: normal;
    line-height: 40px;
  }

  h5 {
    margin-top: 0;
  }

  .document-teaser {
    .heading {
      font-size: 1.4rem;
      text-transform: unset;
      color: $dark-gray;
    }

    &__link {
      font-family: var(--sp-font-secondary);
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
