@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";

@import "../../Atoms/Button/Button.scss";

.paragraph__hero-image-text {
  &.portrait {
    background: linear-gradient(90deg, $white 30%, $blue 30%);

    .hero-image {
      margin: $spacer*2 0;

      @media (max-width: 767px) {
        bottom: 0;
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (max-width: 767px) {
    background: none !important;
    padding: 0 !important;
  }

  a {
    @extend .button--secondary;
    width: fit-content;

    .button__icon {
      color: $white !important;
    }
  }

  .hero-image {
    position: relative;
    z-index: 100;
  }

  .hero-text {
    background: $blue;
    color: $white;
    height: 100%;
    padding: 20px 20px 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    .eyebrow {
      color: $dark-gray;
      margin: 0;
    }

    h2 {
      font-weight: bold;

      @media (max-width: 991px) {
        font-size: calc(1.375rem + 0.5vw);
      }
    }

    h3 {
      font-weight: 300;
      text-transform: initial;

      @media (max-width: 991px) {
        font-size: calc(1.2875rem);
      }
    }

    h2,
    h3 {
      margin-top: 0;
      margin-bottom: 8px;
      color: #fff;
      font-family: var(--sp-font-secondary);
    }
  }

  &.landscape {
    .hero-text {
      background-color: transparent;
      color: $body-text;
    }

    h2,
    h3 {
      color: $blue;
    }
  }

  &.portrait {
    .hero-text {
      @include media-breakpoint-down(md) {
        padding: $spacer;
      }
    }

    .hero-image {
       @include media-breakpoint-down(md) {
        margin: 0;
      }   
    }

    .eyebrow {
      color: $white;
    }

    a {

      &.button {
        text-align: left;
        color: $white;
        background-color: transparent;
        box-shadow: none;
        border-color: $white;
      }
    }
  }
}
