@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__media {
  p.eyebrow {
    font-family: var(--sp-font-secondary);
    font-size: 18px;
    font-weight: 600;
    line-height: 1.78;
    color: #012169;
    margin: 0;
  }

  .heading {
    margin: 0;
    margin-bottom: $spacer * 0.5;
    font-family: var(--sp-font-secondary);
    font-size: 24px;
    font-weight: 300;
    line-height: 1.67;
    color: $dark-gray;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  figcaption,
  .caption {
    margin-bottom: 0;
  }
}
