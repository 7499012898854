@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.utility_menu_wrapper {
  background: $blue;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $spacer;

  &::before {
    content: "";
    margin-bottom: $spacer;
    border-top: 1px solid $shale-blue;
  }

  @include media-breakpoint-up(lg) {
    height: 48px;
    flex-direction: row;
    border-top: none;
    background: transparent;
    width: auto;
  }
}

.navbar-nav .utilitymenu li a.nav-link {
  color: $between-med-light-gray;
  padding: $spacer * 0.5 0;

  @include media-breakpoint-up(lg) {
    padding: $spacer $spacer $spacer 0;
  }

  &:hover,
  &:focus,
  &.active {
    color: white;
    @include media-breakpoint-up(lg) {
      color: $white;
      text-decoration: underline;
    }
  }
}

.utilitymenu {
  justify-content: flex-start;

  @include media-breakpoint-up(lg) {
    height: 48px;
    justify-content: flex-end;
  }

  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    height: 100%;
    align-items: stretch;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    li {
      font-family: var(--sp-font-primary);
      font-size: 0.85rem;
      font-weight: 600;
      display: flex;

      a {
        color: white;
        display: flex;
        align-items: center;
        
        @include media-breakpoint-up(lg) {
          color: $dark-blue;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
